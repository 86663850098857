//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["order", "options", "menu", "location"]),
    filteredProducts() {
      return this.order.products.filter(product => {
        return (
          product.name.toLowerCase() !== "need togo utensils" && product.name.toLowerCase() !== "no rolls"
        );
      });
    }
  },
  async mounted() {
    if (this.$route.path.match(/^\/order\/checkout/)) {
      await this.$api.validateOrder();
    }
  },
  methods: {
    async removeItem(p) {
      await this.$api.removeFromOrder(p.id);
      if (this.filteredProducts.length === 0) {
        if (this.order.coupon) {
          await this.$api.removeCoupon()
          await this.$api.abandonOrder()
        }
        this.$store.commit('setGiftcards', [])
        this.$store.commit('setSelectedGiftcards', [])
        this.$store.commit('setSelectedSavedGiftcards', [])
        this.$store.commit('setOrder', null)
        this.$router.push("/order/");
      } else if (this.$route.path.match(/^\/order\/checkout/)) {
        await this.$api.validateOrder();
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          value: p.cost.total * p.quantity,
          currency: "USD",
          location_name: this.location.name,
          location_id: this.location.id,
          items: [{
            item_id: p.product_id,
            item_name: p.name,
            quantity: p.quantity,
          }],
        }
      })
    },
    edit(e) {
      if (this.$route.path.match(/order\/product/)) {
        let id = e.product_id;
        let defaults = [];
        let quantities = [];

        this.menu.categories.map(c => {
          c.products.map(p => {
            if (p.id == id) {
              id = p.sku;
            }
          });
        });
        this.order.products.map(p => {
          if (e.id == p.id) {
            p.choices.map(c => {
              defaults.push(c.option_id);
              quantities.push(c.quantity);
            });
          }
        });

        this.$store.dispatch("product/getProduct", {
          id,
          defaults,
          quantities
        });
      }
      this.$store.commit("setShowCart", false);
      this.$router.push(
        `${this.options.orderProductPage}?id=${e.product_id}&cid=${e.id}`
      );
    },
    async decrease(p) {
      if (p.quantity > 1) {
        let newProd = {
          id: p.id,
          quantity: p.quantity - 1,
          choices: []
        };
        p.choices.map(c => {
          newProd.choices.push({
            id: c.option_id,
            quantity: 1
          });
        });
        await this.$api.updateInOrder([newProd]);
        if (this.$route.path.match(/^\/order\/checkout/)) {
          await this.$api.validateOrder();
        }
      }
    },
    async increase(p) {
      if (p.quantity < 99) {
        let newProd = {
          id: p.id,
          quantity: p.quantity + 1,
          choices: []
        };
        p.choices.map(c => {
          newProd.choices.push({
            id: c.option_id,
            quantity: 1
          });
        });
        await this.$api.updateInOrder([newProd]);
        if (this.$route.path.match(/^\/order\/checkout/)) {
          await this.$api.validateOrder();
        }
      }
    }
  }
};
